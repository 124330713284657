var jQuery = require('jquery');

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
global.toastr = require("toastr");
window.$ = window.jQuery = jQuery;
import 'select2'
import 'bootstrap'
import "@hotwired/turbo-rails"
Turbo.session.drive = false


import { backToTop, deleteTenderModal,  actionConfirmModal, fetchCountries, initFlatpickr, sidebar, stepWizard, subscribePopup, actionAdminConfirmModal } from './home';

backToTop();
subscribePopup();
// fetchCountries();
// stepWizard();
sidebar();
deleteTenderModal();
actionConfirmModal();
initFlatpickr();
actionAdminConfirmModal();
