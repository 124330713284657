// let COUNTRY_CODE_URL="https://backend-yeewuna-keycloak.finetech.solutions/services/core/api/yeewuna/countries"
let COUNTRY_CODE_URL=window.location.origin+"/countries";


const backToTop = () => {
    $( document ).on('turbolinks:load', function() {
        var scrollPos = 0;
        var element = $('#back2top');
        $(window).scroll(function() {
            var scrollCur = $(window).scrollTop();
            if (scrollCur > scrollPos) {
                // scroll down
                if (scrollCur > 500) {
                    element.addClass('active');
                } else {
                    element.removeClass('active');
                }
            } else {
                // scroll up
                element.removeClass('active');
            }

            scrollPos = scrollCur;
        });

        element.on('click', function() {
            $('html, body').animate(
                {
                    scrollTop: '0px',
                },
                800
            );
        });
    });
};

const subscribePopup = () => {
    $(document).on('change', '#not-show', function() {
        if(this.checked) {
            //Do stuff
            console.log('checked');
            localStorage.setItem('not_show', 'true');
        }else {
            console.log('unchecked');
            localStorage.setItem('not_show', 'false');
        }
    });
    if (!localStorage.hasOwnProperty("not_show") || localStorage.getItem('not_show') != 'true') {
        var subscribe = $('#subscribe'),
        time = subscribe.data('time');
        setTimeout(function() {
            if (subscribe.length > 0) {
                subscribe.addClass('active');
                $('body').css('overflow', 'hidden');
            }
        }, time);
        $('.ps-popup__close').on('click', function(e) {
            e.preventDefault();
            $(this)
                .closest('.ps-popup')
                .removeClass('active');
            $('body').css('overflow', 'auto');
        });
        $('#subscribe').on('click', function(event) {
            if (!$(event.target).closest('.ps-popup__content').length) {
                subscribe.removeClass('active');
                $('body').css('overflow-y', 'auto');
            }
        });
    }
};

const  matchStart = (params, data) => {
    // If there are no search terms, return all of the data
    if ($.trim(params.term) === '') {
      return data;
    }
  
    // Skip if there is no 'children' property
    if (typeof data.children === 'undefined') {
      return null;
    }
  
    // `data.children` contains the actual options that we are matching against
    var filteredChildren = [];
    $.each(data.children, function (idx, child) {
      if (child.text.toUpperCase().indexOf(params.term.toUpperCase()) == 0) {
        filteredChildren.push(child);
      }
    });
  
    // If we matched any of the timezone group's children, then set the matched children on the group
    // and return the group object
    if (filteredChildren.length) {
      var modifiedData = $.extend({}, data, true);
      modifiedData.children = filteredChildren;
  
      // You can return modified objects from here
      // This includes matching the `children` how you want in nested data sets
      return modifiedData;
    }
  
    // Return `null` if the term should not be displayed
    return null;
}

const fetchCountries = () => {
    $( document ).on('turbolinks:load', async function() {
        let current_controller = $("body").data("controller");

        if (current_controller == "registrations") {
            let response = await fetch(COUNTRY_CODE_URL);

            if (response.ok) {
                let data = await response.json();
                let countries = data.map(country => {
                    return {
                        id: country.code,
                        text: country.name,
                        flag: country.flag,
                        code: country.code
                    }
                });
                $('#user_country_code').select2({
                    placeholder: "Indicatif",
                    width: '100%',
                    allowClear: true,
                    templateResult: formatCountry,
                    data: countries
                });
            }else { 
                $('#user_country_code').select2({
                    placeholder: "Indicatif",
                    width: '100%',
                    templateResult: formatCountry,
                    data: []
                });
            }
        }
        
    });
};

const  formatCountry = (country) => {
    if (!country.id) {
      return country.text;
    }
    var $country = $(
      '<span>  <img src="'+country.flag+'"  class="img-flag" /> ' +country.text+ '( +'+ country.code+')'+ '</span>'
    );
    return $country;
  };

const deleteModal = () => {
    $( document ).on('turbolinks:load', ()=> {

        $(document).on("hide.bs.modal", function () {
            // This is to ensure that the modal is closed and all cleanup after closing modal or switching to another page
            $(document.body).removeClass("modal-open");
            $(".modal-backdrop").remove();
        });
        
        $('.delete-confirm').click(function(e) {
            e.preventDefault();
            jQuery.noConflict(); 
            jQuery('#delete-confirm').modal('show'); 
            let elt = $(this).data('element')
            let title = $(this).data('title')
            let url = $(this).data('url')

            $('#delete-confirm').find('.delete-modal-title').text(title)
            $('#delete-confirm').find('.delete-modal-element').text(elt)
            $('#delete-confirm').find('.delete-btn').attr('href', url)

            
        });
    });
}

const stepWizard = () => {
    // ------------step-wizard-------------
    $( document ).on('turbolinks:load', ()=> {
        
        //Wizard
        $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {

            var target = $(e.target);
        
            if (target.parent().hasClass('disabled')) {
                return false;
            }
        });

        $(".next-step").click(function (e) {

            var active = $('.wizard .nav-tabs li.active');
            active.next().removeClass('disabled');
            nextTab(active);

        });

        $(".prev-step").click(function (e) {

            var active = $('.wizard .nav-tabs li.active');
            prevTab(active);

        });
    });

    function nextTab(elem) {
        $(elem).next().find('a[data-toggle="tab"]').click();
    }

    function prevTab(elem) {
        $(elem).prev().find('a[data-toggle="tab"]').click();
    }

    $('.nav-tabs').on('click', 'li', function() {
        $('.nav-tabs li.active').removeClass('active');
        $(this).addClass('active');
    });
}

const sidebar = () => {
    $( document ).on('turbolinks:load', ()=> {
        console.log('sidebar')
        // let arrow = document.querySelectorAll(".arrow");
        // for (var i = 0; i < arrow.length; i++) {
        //     arrow[i].addEventListener("click", (e)=>{
        //         let arrowParent = e.target.parentElement.parentElement;//selecting main parent of arrow
        //         arrowParent.classList.toggle("showMenu");
                
        //     });
        // }
        // let sidebar = document.querySelector(".sidebar");
        // let sidebarBtn = document.querySelector(".bx-menu");
        // console.log(sidebarBtn);
        // sidebarBtn.addEventListener("click", ()=>{
        //     sidebar.classList.toggle("close");
        // });
    });
}

const deleteTenderModal = () => {
    $( document ).on('turbolinks:load', ()=> {
        let current_controller = $("body").data("controller");
        console.log(current_controller);

        flatpickr('.datepicker', { })

        if(["tenders", "users", "vendor_tenders", "payment_terms"].includes(current_controller) || ["index", "show"].includes(current_controller)) {
            $(document).on("hide.bs.modal", function () {
                // This is to ensure that the modal is closed and all cleanup after closing modal or switching to another page
                $(document.body).removeClass("modal-open");
                $(".modal-backdrop").remove();
            });

            $('.delete-confirm').click(function(e) {
                e.preventDefault();
                console.log('delete')
                jQuery.noConflict(); 
                let elt = $(this).data('element')
                let title = $(this).data('name')
                let url = $(this).data('url')

                console.log(`${elt} ${title} ${url}`)
    
                $('#deleteProduct').find('.delete-modal-title').text(title)
                $('#deleteProduct').find('.delete-modal-element').text(elt)
                $('#deleteProduct').find('.delete-btn').attr('href', url)
    
                
            });

        }
    });
}

const actionConfirmModal = () => {
    $( document ).on('turbolinks:load', ()=> {
        let current_controller = $("body").data("controller");

        flatpickr('.datepicker', { })

        if(["tenders", "users", "vendor_tenders", "tender_responses"].includes(current_controller) || ["index", "show"].includes(current_controller)) {
            $(document).on("hide.bs.modal", function () {
                // This is to ensure that the modal is closed and all cleanup after closing modal or switching to another page
                $(document.body).removeClass("modal-open");
                $(".modal-backdrop").remove();
            });

            $('.action-confirm').click(function(e) {
                e.preventDefault();
                jQuery.noConflict(); 
                let elt = $(this).data('action');
                let title = $(this).data('name');
                let message = $(this).data('message');
                let url = $(this).data('url');

                console.log(`${elt} ${title} ${url}`);
    
                $('#confirmAction').find('.confirm-modal-title').text(title);
                $('#confirmAction').find('.confirm-modal-action').text(elt);
                $('#confirmAction').find('.confirm-modal-message').text(message);
                $('#confirmAction').find('.confirm-btn').attr('href', url);
    
                
            });

        }
    });
}

const actionAdminConfirmModal = () => {
    $( document ).on('turbolinks:load', ()=> {
        let current_controller = $("body").data("controller");


        if(["tender_products"].includes(current_controller) || ["index", "show"].includes(current_controller)) {
            $(document).on("hide.bs.modal", function () {
                // This is to ensure that the modal is closed and all cleanup after closing modal or switching to another page
                $(document.body).removeClass("modal-open");
                $(".modal-backdrop").remove();
            });

            $('.response-confirm').click(function(e) {
                e.preventDefault();
                jQuery.noConflict(); 
                let elt = $(this).data('action');
                let title = $(this).data('name');
                let message = $(this).data('message');
                let url = $(this).data('url');

                console.log(`${elt} ${title} ${url}`);
    
                $('#confirmResponse').find('.confirm-modal-title').text(title);
                $('#confirmResponse').find('.confirm-modal-action').text(elt);
                $('#confirmResponse').find('.confirm-modal-message').text(message);
                $('#confirmResponse').find('.confirm-btn').attr('href', url);
    
                
            });

        }
    });
}

const initFlatpickr = () => {
    $( document ).on('turbolinks:load', ()=> {
        flatpickr('#tender_close_at', {
            enableTime: false,
            altInput: true,
            dateFormat: "d-m-Y",
            altFormat: "j F, Y",
            minDate: "today"
        })

        flatpickr('#tender_product_delivery_delay', {
            enableTime: false,
            altInput: true,
            dateFormat: "d-m-Y",
            altFormat: "j F, Y",
            minDate: "today"
        })
        
    })
}

const initPayment = () => {
    $( document ).on('turbolinks:load', ()=> {
        let current_controller = $("body").data("controller");

        if (current_controller == "checkout") {
            $('.payment-box').click(function(e) {
                e.preventDefault();

                jQuery.noConflict(); 
                let url = $(this).data('url')
                console.log(url)
                window.location.href = url
            });
        }
        
    });
}



export { initPayment ,backToTop, subscribePopup, fetchCountries, deleteModal, stepWizard, sidebar, deleteTenderModal, initFlatpickr, actionConfirmModal, actionAdminConfirmModal };
